import { getAuth } from "firebase/auth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Splash from "../Splash";

export default function Index() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(getAuth().currentUser ? "/account/select" : "/join");
  }, [navigate]);
  return <Splash message="Loading" />;
}
