import { RefObject, useEffect, useRef, useState } from "react";
import useOnScreen from "../../../utils/useOnScreen";
import { getBlob, getStorage, ref } from "firebase/storage";

const cache = new Map<string, Promise<string>>()

function load(picture: any, title?: any) {
  if (!cache.has(picture)) {
    if (picture)
      cache.set(picture, getBlob(
        ref(getStorage(), picture)
      ).then(
        blob => URL.createObjectURL(blob)
      ).catch(
        () => `https://api.dicebear.com/5.x/initials/svg?seed=${encodeURIComponent(
          title
        )}`
      ))
    else

      cache.set(picture, Promise.resolve(`https://api.dicebear.com/5.x/initials/svg?seed=${encodeURIComponent(
        title
      )}`))
  }
  return cache.get(picture)!
}

export default function Avatar({ picture, title }: any) {
  const [src, setSrc] = useState<string>()
  const $ref = useRef<HTMLImageElement>() as RefObject<HTMLImageElement>
  const onScreen = useOnScreen($ref)
  useEffect(() => {
    if (onScreen && (picture || $ref.current?.title !== title)) {
      load(picture, title).then(setSrc)
    }
  }, [onScreen, picture, title])
  return <img ref={$ref} src={src} alt={title} className="inline-block h-10 w-10 rounded-full" />
}