import { Helmet } from "react-helmet-async";
import {
  Link,
  Outlet,
  RouteObject,
  useOutletContext
} from "react-router-dom";
import MetaIntegrationRoute from "./meta";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShopify,
  faTwitter, faFacebookMessenger,
  faInstagram,
  faWhatsapp,
  faTelegram
} from "@fortawesome/free-brands-svg-icons";
import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import type { Account } from "@reqasio/firebase/firestore";
import { IntegrationStatus } from "@reqasio/firebase/firestore";
import {
  Cog6ToothIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import FacebookIntegrationRoute from "./facebook";
import InstagramIntegrationRoute from "./instagram";
import WhatsAppIntegrationRoute from "./whatsapp";
import ShopifyIntegrationRoute from "./shopify";

export const INTEGRATIONS: [
  keyof Account["integrations"],
  string | JSX.Element,
  IconProp,
  JSX.Element | string,
  boolean?
][] = [
  [
    "facebook",
    "Facebook Messenger for Business",
    faFacebookMessenger,
    "Offer more than just support with Facebook Messenger, one of the most powerful online tools for customer contact today.",
  ],
  [
    "instagram",
    "Instagram for Business",
    faInstagram,
    "Connect and engage with the Instagram community: have meaningful conversations, increase customer satisfaction, drive sales.",
  ],
  [
    "whatsapp",
    "WhatsApp Business",
    faWhatsapp,
    "Provide always-on and personalized support and turn conversations into revenue on one of the most popular messaging channels.",
  ],
  ["shopify", "Shopify", faShopify, "Turn conversations into checkouts"],
  [
    "twitter",
    "Twitter for Business",
    faTwitter,
    "Use Twitter as a stage for your products and services. Spread your message, promote your business and grow your audience.",
    true,
  ],
  [
    "telegram",
    "Telegram for Business",
    faTelegram,
    "Add Telegram to your digital communication strategy and connect with your customers.",
    true,
  ],
];

interface LoaderType {
  integrations?: Account["integrations"];
}

export function Integrations() {
  const { account } = useOutletContext<{ account: LoaderType }>();
  return (
    <>
      <Helmet title="Integrations" />
      <h2 className="text-xl font-semibold text-heading">Integrations</h2>
      <div className="mt-4">
        <div className="grid gap-4 xl:grid-cols-2">
          {INTEGRATIONS.map(([key, name, icon, description, soon]) => (
            <div
              key={key}
              className="rounded-xl bg-layer-2 px-6 py-4 hover:drop-shadow"
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-4">
                  <div className="h-14 w-14 rounded-xl bg-layer-3 p-2">
                    <FontAwesomeIcon className="h-10 w-10" icon={icon} />
                  </div>
                  <h3 className="text-base font-semibold text-heading">
                    {name}
                  </h3>
                </div>
                <div>
                  {!account?.integrations?.[key] ? (
                    soon ? (
                      <span className="text-sm">Coming soon</span>
                    ) : (
                      <Link
                        to={key}
                        className="inline-flex cursor-pointer items-center justify-center rounded-xl border-2 border-primary bg-primary px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:border-primary-accent hover:bg-primary-accent focus:outline-none focus:ring-2 focus:ring-orange-400/80 focus:ring-offset-0 disabled:opacity-30 disabled:hover:border-primary disabled:hover:bg-primary disabled:hover:text-white dark:focus:ring-white/80"
                      >
                        Connect
                      </Link>
                    )
                  ) : (
                    <Link
                      to={key}
                      className="inline-flex cursor-pointer items-center justify-center rounded-xl border-none border-transparent bg-transparent p-2.5 font-semibold text-text hover:bg-heading/5 hover:text-heading focus:bg-heading/5 focus:outline-none focus:ring-2 focus:ring-heading/80 focus:ring-offset-0 disabled:opacity-30 disabled:hover:bg-transparent disabled:hover:text-text"
                    >
                      {
                        {
                          [IntegrationStatus.successfull]: (
                            <Cog6ToothIcon className="h-6 w-6" />
                          ),
                          [IntegrationStatus.warning]: (
                            <ExclamationCircleIcon className="h-6 w-6 text-orange-600" />
                          ),
                          [IntegrationStatus.error]: (
                            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" />
                          ),
                          [IntegrationStatus.none]: undefined,
                        }[account?.integrations?.[key]!]
                      }
                    </Link>
                  )}
                </div>
              </div>
              <div className="mt-4">{description}</div>
            </div>
          ))}
        </div>
      </div>
      <Outlet />
    </>
  );
}

const IntegrationsRoute: RouteObject = {
  id: "integrations",
  path: "integrations",
  children: [
    {
      index: true,
      element: <Integrations />,
    },
    MetaIntegrationRoute,
    FacebookIntegrationRoute,
    InstagramIntegrationRoute,
    WhatsAppIntegrationRoute,
    ShopifyIntegrationRoute,
  ],
};

export default IntegrationsRoute;
