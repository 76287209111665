import {
  EllipsisHorizontalIcon,
  ArrowUturnLeftIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import { getAuth } from "firebase/auth";
import { getBlob, getStorage, ref } from "firebase/storage";
import {
  addDoc,
  collection,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
} from "firebase/firestore";
import {
  Fragment,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { RouteObject, useOutletContext } from "react-router-dom";
import { MessageDirection } from "@reqasio/firebase/firestore/message.direction";
import { MessageType } from "@reqasio/firebase/firestore";
import throttle from "lodash.throttle";
import { Menu, Transition } from "@headlessui/react";
import useOnScreen from "../../../utils/useOnScreen";
import Avatar from "./avatar";

const FBXFBMLParse = throttle(() => {
  try {
    window.FB?.XFBML.parse();
  } catch {
    FBXFBMLParse();
  }
}, 1000);
const IGXFBMLParse = throttle(() => {
  try {
    // @ts-ignore
    window.instgrm?.Embeds.process();
  } catch {
    IGXFBMLParse();
  }
}, 1000);

export function OEmbed({ oembed }: any) {
  useEffect(() => {
    oembed.type === "facebook" ? FBXFBMLParse() : IGXFBMLParse();
  }, []);
  return oembed.type === "facebook" ? (
    <div data-href={oembed.url} className="fb-post">
      <blockquote cite={oembed.url} className="fb-xfbml-parse-ignore" />
    </div>
  ) : (
    <blockquote
      className="instagram-media"
      data-instgrm-permalink={oembed.url}
      data-instgrm-version="12"
    />
  );
}

export function Media({ message }: any) {
  const { account, conversation } = useOutletContext() as any;
  const [src, setSrc] = useState<string>()
  const $ref = useRef<HTMLDivElement>() as RefObject<HTMLDivElement>
  const onScreen = useOnScreen($ref)
  useEffect(() => {
    if (onScreen && !src) {
      getBlob(
        ref(getStorage(), decodeURIComponent(message.image || message.video || message.audio) || [account.id, conversation.id, message.id].join('/'))
      ).then(
        blob => setSrc(URL.createObjectURL(blob))
      ).catch(console.error)
    }
  }, [onScreen, src])
  return <div ref={$ref}>
    {message.image && <img src={src} alt={message.id} />}
    {message.video && (
      <video
        preload="metadata"
        controls
        src={src}
      />
    )}
    {message.audio && (
      <audio
        preload="metadata"
        controls
        src={src}
      />
    )}</div>
}

export function Messages() {
  const { account, conversation } = useOutletContext() as any;
  const [messages, setMessages] = useState<Array<any>>();
  const [replyTo, setReplyTo] = useState<string>();
  const [type, setType] = useState<MessageType>(MessageType.text);
  const messageInputRef = useRef<HTMLInputElement>();
  const replyToMessage = useMemo(
    () => messages?.find((message) => message.id === replyTo),
    [replyTo, messages]
  );
  useEffect(() => {
    setMessages([])
    return onSnapshot(
      query(
        collection(
          getFirestore(),
          "accounts",
          account.id,
          "conversations",
          conversation.id,
          "messages"
        ),
        orderBy("timestamp", "desc")
      ),
      (snapshot) =>
        setMessages(() =>
          snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        )
    );
  }, [account, conversation]);
  const add = useCallback(
    (direction: MessageDirection) => {
      const message: any = {
        type,
        text: messageInputRef.current?.value,
        direction,
        timestamp: serverTimestamp(),
        agent: getAuth().currentUser?.uid,
      };
      if (replyTo) message.replyTo = replyTo;
      addDoc(
        collection(
          getFirestore(),
          "accounts",
          account.id,
          "conversations",
          conversation.id,
          "messages"
        ),
        message
      ).then(() => {
        setType(MessageType.text);
        setReplyTo(undefined);
        messageInputRef.current!.value = "";
        messageInputRef.current!.focus();
      });
    },
    [account, conversation, messageInputRef, replyTo, type]
  );
  return (
    <>
      <div className="flex h-full flex-col">
        <div className="flex h-16 w-full items-center justify-between px-6">
          <div className="flex flex-1 items-center space-x-3">
            <Avatar picture={conversation.picture} title={conversation.title} />
            <span className="text-sm font-medium text-text">
              {conversation.title}
            </span>
          </div>
          <div>
            <button
              type="button"
              className="inline-flex cursor-pointer items-center justify-center rounded-xl border-none border-transparent bg-transparent p-2 font-semibold text-text hover:bg-heading/5 hover:text-heading focus:bg-heading/5 focus:outline-none focus:ring-2 focus:ring-heading/80 focus:ring-offset-0 disabled:opacity-30 disabled:hover:bg-transparent disabled:hover:text-text"
            >
              <EllipsisHorizontalIcon className="h-5 w-5" />
            </button>
          </div>
        </div>
        <hr className="border-hr dark:border-muted-1" />
        <div className="w-full flex-1 overflow-hidden">
          <div className="flex h-full flex-col-reverse space-y-6 space-y-reverse px-6 py-4 sm:py-6 overflow-y-auto scrollbar scrollbar-thin">
            {messages?.map((message) => (
              <div
                key={message.id}
                className={classNames("flex group/message", {
                  "justify-start": message.direction === MessageDirection.in,
                  "justify-end": message.direction === MessageDirection.out,
                }, `direction-${MessageDirection[message.direction]}`, `type-${MessageType[message.type]}`)}
              >
                <div
                  className={classNames("flex items-end", {
                    "flex-row-reverse":
                      message.direction === MessageDirection.out,
                    "flex-1": message.direction === MessageDirection.none,
                  })}
                >
                  {message.direction ? (
                    <Avatar picture={message.direction === MessageDirection.in && conversation.picture} title={message.direction === MessageDirection.in ? conversation.title : account.name} />
                  ) : ''}
                  <div className="mr-4" />
                  <div
                    className={classNames(
                      "relative overflow-hidden rounded-xl",
                      {
                        "px-4 py-2 bg-muted-1 max-w-lg":
                          message.type === MessageType.text,
                        "bg-muted-1": message.type === MessageType.comment,
                        "w-full text-center":
                          message.direction === MessageDirection.none,
                        "max-w-xs": [
                          MessageType.image,
                          MessageType.audio,
                          MessageType.video,
                        ].includes(message.type),
                        "rounded-tl-none":
                          message.direction === MessageDirection.in,
                        "rounded-tr-none !bg-blue-600 text-white":
                          message.direction === MessageDirection.out,
                      }
                    )}
                  >
                    {message.oembed && <OEmbed oembed={message.oembed} />}
                    <div
                      className={classNames({
                        "px-4 pb-2": message.type === MessageType.comment,
                      })}
                    >
                      {message.type === MessageType.comment && (
                        <span className="text-xs font-bold">
                          Commented: <br />
                        </span>
                      )}

                      {(message.image || message.video || message.audio) && <Media message={message} />}
                      <span
                        className={classNames("text-sm font-medium whitespace-pre-wrap", {
                          // "text-heading": message.direction != MessageDirection.none,
                          "text-muted":
                            message.direction === MessageDirection.none,
                        })}
                      >
                        {message.text || message.event}
                      </span>
                    </div>
                  </div>
                  <Menu
                    as="div"
                    className={classNames(
                      'relative ml-2 p-2 invisible group-hover/message:visible rounded-xl cursor-pointer hover:bg-heading/5',
                      {
                        'hidden': message.type !== MessageType.comment
                      }
                    )}
                  >
                    <Menu.Button as={ArrowUturnLeftIcon} className="h-5 w-5" />
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute z-30 left-7 bottom-7 mt-2 w-56 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div
                          className="px-1 py-1"
                          onClick={() => setReplyTo(message.id)}
                        >
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                onClick={() => setType(MessageType.comment)}
                                className={`${active
                                  ? "bg-violet-500 text-white"
                                  : "text-gray-900"
                                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                              >
                                Reply with a comment
                              </button>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                onClick={() => setType(MessageType.text)}
                                className={`${active
                                  ? "bg-violet-500 text-white"
                                  : "text-gray-900"
                                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                              >
                                Reply with a private message
                              </button>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                  {/* <div
                    onClick={() => setReplyTo(message.id)}
                    className="ml-2 p-2 invisible group-hover/message:visible rounded-xl cursor-pointer hover:bg-heading/5"
                  >
                    <ArrowUturnLeftIcon className="h-5 w-5" />
                  </div> */}
                </div>
              </div>
            ))}
          </div>
        </div>
        <hr className="border-hr dark:border-muted-1" />
        {replyTo && (
          <div className="mt-2 mx-2 sm:mx-6 relative">
            <XMarkIcon
              className="w-7 h-7 absolute rounded-xl cursor-pointer hover:bg-heading/5 p-1 top-0 right-0"
              onClick={() => {
                setReplyTo(undefined);
                setType(MessageType.text);
              }}
            />
            <span className="text-sm">Replying to the comment:</span>
            <br />
            {replyToMessage.text}
          </div>
        )}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            add(MessageDirection.out);
          }}
          className="flex h-16 w-full flex-shrink-0 items-center px-2 shadow-lg sm:px-6"
        >
          <div className="flex flex-1 space-x-2">
            <div className="flex-1">
              <label
                htmlFor="message"
                className="sr-only block text-sm font-semibold text-heading"
              >
                Message
              </label>
              <input
                ref={messageInputRef as any}
                name="message"
                placeholder="Message..."
                className="block w-full rounded-xl border-2 border-muted-3 bg-transparent px-4 py-2.5 font-semibold text-heading placeholder:text-text/50 focus:border-primary focus:outline-none focus:ring-0 sm:text-sm"
              />
            </div>
            <button
              type="submit"
              className="inline-flex cursor-pointer items-center justify-center rounded-xl border-2 border-primary bg-primary px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:border-primary-accent hover:bg-primary-accent focus:outline-none focus:ring-2 focus:ring-orange-400/80 focus:ring-offset-0 disabled:opacity-30 disabled:hover:border-primary disabled:hover:bg-primary disabled:hover:text-white dark:focus:ring-white/80"
            >
              Send
            </button>
            {/* <button
              // type="button"
              className="inline-flex cursor-pointer items-center justify-center rounded-xl border-none border-secondary bg-secondary p-2.5 font-semibold text-white shadow-sm hover:border-secondary-accent hover:bg-secondary-accent focus:outline-none focus:ring-2 focus:ring-orange-400/80 focus:ring-offset-0 disabled:opacity-30 disabled:hover:border-secondary disabled:hover:bg-secondary disabled:hover:text-white dark:focus:ring-white/80"
              onClick={() => add(MessageDirection.in)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                aria-hidden="true"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </button>
            <button
              className="inline-flex cursor-pointer items-center justify-center rounded-xl border-none border-secondary bg-secondary p-2.5 font-semibold text-white shadow-sm hover:border-secondary-accent hover:bg-secondary-accent focus:outline-none focus:ring-2 focus:ring-orange-400/80 focus:ring-offset-0 disabled:opacity-30 disabled:hover:border-secondary disabled:hover:bg-secondary disabled:hover:text-white dark:focus:ring-white/80"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                aria-hidden="true"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4v16m8-8H4"
                />
              </svg>
            </button> */}
          </div>
        </form>
      </div>
    </>
  );
}

const MessagesRoute: RouteObject = {
  id: "messages",
  index: true,
  element: <Messages />,
};

export default MessagesRoute;
