import { RouteObject, redirect } from "react-router-dom";
import Splash from "../../Splash";
import {
  getAuth,
  signOut
} from "firebase/auth";

export function LogoutPage() {

}

const LogoutRoute: RouteObject = {
  id: "logout",
  path: "logout",
  element: <Splash message="Logging out..." />,
  async loader() {
    await signOut(getAuth())
    return redirect('/join')
  }
}

export default LogoutRoute