import { getAuth } from "firebase/auth";
import {
  addDoc,
  collection,
  getFirestore,
} from "firebase/firestore";
import type { CollectionReference } from "firebase/firestore";
import { Helmet } from "react-helmet-async";
import { Form, redirect, RouteObject } from "react-router-dom";
import type { Account } from "@reqasio/firebase/firestore";

export function AccountCreate() {
  return (
    <>
      <Helmet title="Create Account" />
      <div className="h-full min-h-screen">
        <div className="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="flex flex-col justify-center sm:mx-auto sm:w-full sm:max-w-md">
            <div className="mx-auto h-9 text-heading" />
          </div>
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="px-4 sm:px-10">
              <Form method="post" className="flex flex-col space-y-4">
                <div>
                  <label
                    htmlFor="name"
                    className="block text-sm font-semibold text-heading"
                  >
                    Company name
                  </label>
                  <input
                    id="name"
                    name="name"
                    type="text"
                    className="mt-2 block w-full rounded-xl border-2 border-muted-3 bg-transparent px-4 py-2.5 font-semibold text-heading placeholder:text-text/50 focus:border-primary focus:outline-none focus:ring-0 sm:text-sm"
                  />
                </div>
                <button
                  type="submit"
                  className="group/button inline-flex cursor-pointer items-center justify-center rounded-xl border-2 border-primary bg-primary px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:border-primary-accent hover:bg-primary-accent focus:outline-none focus:ring-2 focus:ring-orange-400/80 focus:ring-offset-0 disabled:opacity-30 disabled:hover:border-primary disabled:hover:bg-primary disabled:hover:text-white dark:focus:ring-white/80"
                >
                  <svg className="hidden group-focus/button:block animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Create
                </button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const AccountCreateRoute: RouteObject = {
  path: "account/create",
  element: <AccountCreate />,
  async action({ request }) {
    const formData = await request.formData();
    const account = Object.fromEntries(formData) as unknown as Account;
    account.createdBy = getAuth().currentUser!.uid;
    // account.createdAt = serverTimestamp();
    const snapshot = await addDoc<Account, any>(
      collection(getFirestore(), "accounts") as CollectionReference<Account>,
      account
    );
    let accounts: any;
    do {
      await new Promise((resolve) => setTimeout(resolve, 1000 * 0.3));
      accounts = await getAuth()
        .currentUser!.getIdTokenResult(true)
        .then((tokens) => tokens.claims.accounts);
    } while (!accounts?.[snapshot.id]);
    return redirect(`/account/${snapshot.id}/settings/integrations`);
  },
};

export default AccountCreateRoute;
