import {
  Form,
  RouteObject,
  redirect,
  // useActionData,
  useSearchParams,
  useLoaderData,
} from "react-router-dom";
import {
  getAuth,
  signInWithEmailAndPassword,
  // sendSignInLinkToEmail,
  signInWithEmailLink,
  isSignInWithEmailLink,
  // signInAnonymously as _signInAnonymously,
} from "firebase/auth";
// import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Helmet } from "react-helmet-async";
// import { useCallback } from "react";
import Splash from "../Splash";

export function Join() {
  // const navigate = useNavigate();
  // const actionData = useActionData();
  const isSignInWithEmailLink = useLoaderData()
  const [searchParams] = useSearchParams();
  // const signInAnonymously = useCallback(() => {
  //   _signInAnonymously(getAuth()).then(() => navigate("/account/select"));
  // }, []);

  if (isSignInWithEmailLink !== false)
    return <Splash />

  return (
    <>
      <Helmet title="Join" />
      <div className="h-full min-h-screen">
        <div className="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="flex flex-col justify-center sm:mx-auto sm:w-full sm:max-w-md">
            <div className="mx-auto h-9 text-heading" />
          </div>
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="px-4 sm:px-10">
              <Form method="post" className="flex flex-col space-y-4">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-semibold text-heading"
                  >
                    Email
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    className="mt-2 block w-full rounded-xl border-2 border-muted-3 bg-transparent px-4 py-2.5 font-semibold text-heading placeholder:text-text/50 focus:border-primary focus:outline-none focus:ring-0 sm:text-sm"
                  />
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-semibold text-heading"
                  >
                    Password
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    className="mt-2 block w-full rounded-xl border-2 border-muted-3 bg-transparent px-4 py-2.5 font-semibold text-heading placeholder:text-text/50 focus:border-primary focus:outline-none focus:ring-0 sm:text-sm"
                  />
                </div>
                <input
                  type="hidden"
                  name="return"
                  defaultValue={searchParams.get("return")!}
                />
                <button
                  type="submit"
                  className="inline-flex cursor-pointer items-center justify-center rounded-xl border-2 border-primary bg-primary px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:border-primary-accent hover:bg-primary-accent focus:outline-none focus:ring-2 focus:ring-orange-400/80 focus:ring-offset-0 disabled:opacity-30 disabled:hover:border-primary disabled:hover:bg-primary disabled:hover:text-white dark:focus:ring-white/80"
                >
                  Login
                </button>
                {/* <div
                  hidden={!actionData}
                  className="relative rounded-xl bg-layer-2 p-4"
                >
                  <div className="flex items-start space-x-2.5 text-blue-400">
                    <InformationCircleIcon className="h-6 w-6 flex-shrink-0" />
                    <div>
                      <div className="mt-0.5 text-sm font-semibold">
                        Login email sent!
                      </div>
                      <div
                        hidden
                        className="mt-1 text-xs font-medium text-text"
                      >
                        We've sent you an email with a login link, click on it !
                      </div>
                    </div>
                  </div>
                </div> */}
              </Form>
              {/* <hr className="my-8 border-hr" />
              <div className="flex flex-col space-y-4">
                <button
                  type="button"
                  onClick={signInAnonymously}
                  className="group/button inline-flex cursor-pointer items-center justify-center rounded-xl border-2 border-secondary bg-secondary px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:border-secondary-accent hover:bg-secondary-accent focus:outline-none focus:ring-2 focus:ring-orange-400/80 focus:ring-offset-0 disabled:opacity-30 disabled:hover:border-secondary disabled:hover:bg-secondary disabled:hover:text-white dark:focus:ring-white/80"
                >
                  <svg
                    className="hidden group-focus/button:block animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Login Anonymously
                </button>
                <button
                  type="button"
                  className="inline-flex cursor-pointer items-center justify-center rounded-xl border-2 border-muted-3 bg-transparent px-4 py-2.5 text-sm font-semibold text-text shadow-sm hover:text-heading focus:text-heading focus:outline-none focus:ring-2 focus:ring-orange-400/80 focus:ring-offset-0 disabled:opacity-30 disabled:hover:text-text dark:focus:ring-white/80"
                >
                  Sign in with Google
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const JoinRoute: RouteObject = {
  path: "join",
  element: <Join />,
  loader({ request }) {
    const url = new URL(request.url);
    if (isSignInWithEmailLink(getAuth(), request.url)) {
      return signInWithEmailLink(
        getAuth(),
        url.searchParams.get("email")!,
        request.url
      ).then(() =>
        redirect(url.searchParams.get("return") || "/account/select")
      );
    }
    return false;
  },
  async action({ request }) {
    const formData = await request.formData();
    const email: string = formData.get("email")!.toString();
    const password: string = formData.get("password")!.toString();
    const _return: string = formData.get("return")!.toString();

    const url = new URL(request.url);
    url.searchParams.set("email", email);
    url.searchParams.set("return", _return);

    return signInWithEmailAndPassword(getAuth(), email, password).then(
      () => redirect(url.searchParams.get("return") || "/account/select")
    ).catch(error => {
      alert(error.message)
      return true
    })

    // await sendSignInLinkToEmail(getAuth(), email, {
    //   url: url.toString(),
    //   handleCodeInApp: true,
    // }).catch(console.error);
    // return true;
  },
};

export default JoinRoute;
