import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import { Suspense } from "react";
import { Helmet } from "react-helmet-async";
import { Await, Form, LoaderFunctionArgs, RouteObject, defer, useLoaderData } from "react-router-dom";
// import { Form, Formik } from 'formik';

// function Option({ name, title, description, disabled }: { name: string; title: string; description?: string, disabled?: boolean }) {
//   return (
//     <div className="flex items-center justify-between py-4">
//       <div className="flex flex-col flex-grow">
//         <div className="text-lg font-semibold text-gray-900 dark:text-white">{title}</div>
//         <div className="text-base font-normal text-gray-500 dark:text-gray-400">{description}</div>
//       </div>
//       <label className="relative flex items-center cursor-pointer">
//         <input name={name} type="checkbox" className="sr-only" disabled={disabled} />
//         <span className="h-6 bg-gray-200 border border-gray-200 rounded-full w-11 toggle-bg dark:bg-gray-700 dark:border-gray-600"></span>
//       </label>
//     </div>
//   );
// }

export function AIAgentApp() {
  const deferedData = useLoaderData() as any;
  // const { account } = useOutletContext<any>();
  const title = "Customer service chatbot";
  return <>
    <Helmet title={title} />
    <h2 className="text-xl font-semibold text-heading">{title}</h2>
    <Suspense fallback="Loading App...">
      <Await resolve={deferedData.app}>
        {app =>
          // <Formik
          //   initialValues={app}
          //   onSubmit={(values, { setSubmitting }) => {
          //     setTimeout(() => {
          //       alert(JSON.stringify(values, null, 2));

          //     }, 400);
          //   }}
          // >
          // </Formik>
          <>
            <main className="mt-8 grid grid-cols-3 gap-5">
              <Form method="post" className="col-span-3 p-4 mb-4 bg-white rounded-lg shadow-sm sm:p-6 dark:bg-layer-2 xl:mb-0 flex flex-col space-y-4">
                <div className="flow-root">
                  <h3 className="text-xl font-semibold dark:text-white">System</h3>
                  <p className="text-sm font-normal text-gray-500 dark:text-gray-400">Describe bellow the tasks to do and how the the AI agent should behave...</p>
                  <textarea
                    name="context"
                    rows={25}
                    className="mt-2 block w-full rounded-xl border-2 border-muted-3 bg-transparent px-4 py-2.5 font-semibold text-heading placeholder:text-text/50 focus:border-primary focus:outline-none focus:ring-0 sm:text-sm"
                    defaultValue={app.context}
                  />
                </div>
                <button
                  type="submit"
                  className="inline-flex cursor-pointer items-center justify-center rounded-xl border-2 border-primary bg-primary px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:border-primary-accent hover:bg-primary-accent focus:outline-none focus:ring-2 focus:ring-orange-400/80 focus:ring-offset-0 disabled:opacity-30 disabled:hover:border-primary disabled:hover:bg-primary disabled:hover:text-white dark:focus:ring-white/80"
                >
                  Save
                </button>
              </Form>
              {/* <div>
                <div className="p-4 mb-4 bg-white rounded-lg shadow-sm sm:p-6 dark:bg-layer-2 xl:mb-0">
                  <div className="flow-root">
                    <h3 className="text-xl font-semibold dark:text-white">Shared Knowledge Base</h3>
                    <p className="text-sm font-normal text-gray-500 dark:text-gray-400">Select the data you want to share with the AI Agent</p>
                    <div className="divide-y divide-gray-200 dark:divide-gray-700 mt-4">
                      <Option
                        name="faq"
                        title="Frequently Asked Questions"
                      // description="Get Themesberg news, announcements, and product updates"
                      />
                      <Option
                        name="products"
                        title="Store Products"
                        disabled={!account.shopify}
                      // description="Get Themesberg news, announcements, and product updates"
                      />
                    </div>
                  </div>
                </div>
              </div> */}
            </main>
          </>
        }
      </Await>
    </Suspense>
  </>;
}

const AIAgentAppRoute: RouteObject = {
  id: "ai-agent",
  path: "ai-agent",
  children: [
    {
      index: true,
      element: <AIAgentApp />,
      async action({ request, params }) {
        const formData = await request.formData();
        const app = Object.fromEntries(formData);
        console.log('app', app)
        await setDoc(
          doc(getFirestore(), "accounts", params.account!, "apps", "ai-agent"),
          app,
          {
            merge: true,
          }
        )
        console.log('Saved')
        return null
      },
      loader({ params, request }: LoaderFunctionArgs) {
        return defer({
          app: getDoc(doc(getFirestore(), "accounts", params.account!, "apps", "ai-agent")).then(
            (snapshot) => ({ id: snapshot.id, ...snapshot.data() })
          ),
        });
      },
    },
  ],
};

export default AIAgentAppRoute;
