import classNames from "classnames";
import { Helmet } from "react-helmet-async";
import {
  NavLink,
  Outlet,
  RouteObject,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import IntegrationsRoute from "./integrations";
import AppsRoute from "./apps";

const MENU: [string, string, boolean?][] = [
  // [".", "Home", true],
  ["integrations", "Integrations"],
  ["apps", "Apps"],
  // ["documents", "Documents"],
  // ["timeline", "Timeline"],
];

export function Settings() {
  const context = useOutletContext();
  const navigate = useNavigate();
  return (
    <>
      <Helmet title="Settings" />
      <div className="py-8 px-6 md:px-8">
        <main className="mx-auto max-w-content-xl">
          <header>
            <h1 className="text-3xl font-semibold text-heading">Settings</h1>
          </header>
          <div className="mt-4 flex flex-col md:mt-8 md:flex-row md:space-x-4 md:divide-x md:divide-layer-3">
            <div>
              <div>
                <div className="mb-6 block md:mb-0 md:hidden">
                  <label
                    htmlFor="category"
                    className="sr-only block text-sm font-semibold text-heading"
                  >
                    Settings Category
                  </label>
                  <select
                    onChange={(event) => navigate(event.target.value)}
                    className="block w-full rounded-xl border-2 border-muted-3 bg-transparent py-2 pl-3 pr-7 font-medium text-text focus:border-primary disabled:opacity-30 sm:text-sm"
                  >
                    {MENU.map(([value, label]) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </select>
                </div>
                <div
                  className="hidden flex-col items-start space-y-4 md:flex"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  {MENU.map(([key, label, end]) => (
                    <NavLink
                      key={key}
                      className={({ isActive }) =>
                        classNames(
                          "inline-flex cursor-pointer items-center justify-center rounded-xl border-2 px-2 py-1 text-sm font-semibold focus:outline-none disabled:opacity-30",
                          {
                            "border-transparent bg-transparent text-text hover:bg-heading/5 hover:text-heading focus:bg-heading/5 focus:ring-2 focus:ring-heading/80 focus:ring-offset-0 disabled:hover:bg-transparent disabled:hover:text-text":
                              !isActive,
                            "border-secondary bg-secondary text-white shadow-sm hover:border-secondary-accent hover:bg-secondary-accent focus:ring-2 focus:ring-orange-400/80 focus:ring-offset-0 disabled:hover:border-secondary disabled:hover:bg-secondary disabled:hover:text-white dark:focus:ring-white/80":
                              isActive,
                            isActive: isActive,
                          }
                        )
                      }
                      to={key}
                      end={end}
                    >
                      {label}
                    </NavLink>
                  ))}
                </div>
              </div>
            </div>
            <div className="flex-1">
              <div className="md:px-8">
                <Outlet context={context} />
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

const SettingsRoute: RouteObject = {
  id: "settings",
  path: "settings",
  element: <Settings />,
  children: [IntegrationsRoute, AppsRoute],
};

export default SettingsRoute;
