import { DateTime, DurationLikeObject } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";

type DateTimeUnit = keyof DurationLikeObject;
const UNITS: DateTimeUnit[] = [
  "years",
  "months",
  "days",
  "hours",
  "minutes",
  "seconds",
];

export function RelativeTime({ timestamp }: { timestamp: any }) {
  const dateTime = useMemo(
    () => DateTime.fromJSDate(timestamp?.toDate?.() || timestamp),
    [timestamp]
  );
  const [relativeTime, setRelativeTime] = useState<string>();
  const toRelativeTime = useCallback(
    () =>
      setRelativeTime(
        dateTime.toRelative({
          style: "narrow",
        })!
      ),
    [dateTime]
  );
  useEffect(() => {
    let nextTickId: NodeJS.Timeout;
    const next = () => {
      const now = DateTime.now();
      const diffInDilliseconds = now.diff(dateTime, ['milliseconds']).get("milliseconds");
      if (diffInDilliseconds < 1000 * 60) {
        setRelativeTime('Just now')
        nextTickId = setTimeout(next, 1000 * 60 - diffInDilliseconds);
        return;
      }
      const diff = now.diff(dateTime, UNITS);
      let tickNextIn;
      for (const unit of UNITS) {
        const _diff = diff.get(unit);
        if (_diff) {
          tickNextIn =
            dateTime
              .plus({
                [unit]: Math.ceil(diff.shiftTo(unit).get(unit)),
              })
              .toMillis() - now.toMillis();
          break;
        }
      }
      toRelativeTime();
      nextTickId = setTimeout(next, tickNextIn);
    };
    next();

    return () => clearTimeout(nextTickId);
  }, [dateTime, toRelativeTime]);
  return <>{relativeTime}</>;
}
