import { Helmet } from "react-helmet-async";
import { Link, RouteObject } from "react-router-dom";
import AIAgentAppRoute from "./ai-agent";
import FAQAppRoute from "./faq";

const APPS: [
  string,
  string | JSX.Element,
  JSX.Element,
  JSX.Element | string,
  boolean?
][] = [
    [
      "ai-agent",
      "AI Agent",
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        className="h-10 w-10"
      >
        <path
          fill="#669df6"
          d="M20 13.89a.77.77 0 00-1-.16l-7 5.14v.22a.72.72 0 110 1.43.74.74 0 00.45-.15l7.41-5.47a.76.76 0 00.14-1.01z"
        ></path>
        <path
          fill="#aecbfa"
          d="M12 20.52a.72.72 0 010-1.43v-.22l-7-5.14a.76.76 0 00-1 .16.74.74 0 00.16 1l7.41 5.47a.73.73 0 00.44.15z"
        ></path>
        <path
          fill="#4285f4"
          d="M12 18.34a1.47 1.47 0 101.47 1.47A1.47 1.47 0 0012 18.34zm0 2.18a.72.72 0 11.72-.71.71.71 0 01-.72.71z"
        ></path>
        <path
          fill="#aecbfa"
          d="M6 6.11a.76.76 0 01-.75-.75V3.48a.76.76 0 111.51 0v1.88a.76.76 0 01-.76.75z"
        ></path>
        <circle cx="5.98" cy="12" r="0.76" fill="#aecbfa"></circle>
        <circle cx="5.98" cy="9.79" r="0.76" fill="#aecbfa"></circle>
        <circle cx="5.98" cy="7.57" r="0.76" fill="#aecbfa"></circle>
        <path
          fill="#4285f4"
          d="M18 8.31a.76.76 0 01-.75-.76V5.67a.75.75 0 111.5 0v1.88a.75.75 0 01-.75.76z"
        ></path>
        <circle cx="18.02" cy="12.01" r="0.76" fill="#4285f4"></circle>
        <circle cx="18.02" cy="9.76" r="0.76" fill="#4285f4"></circle>
        <circle cx="18.02" cy="3.48" r="0.76" fill="#4285f4"></circle>
        <path
          fill="#669df6"
          d="M12 15a.76.76 0 01-.75-.75v-1.91a.76.76 0 011.51 0v1.89A.76.76 0 0112 15z"
        ></path>
        <circle cx="12" cy="16.45" r="0.76" fill="#669df6"></circle>
        <circle cx="12" cy="10.14" r="0.76" fill="#669df6"></circle>
        <circle cx="12" cy="7.92" r="0.76" fill="#669df6"></circle>
        <path
          fill="#4285f4"
          d="M15 10.54a.76.76 0 01-.75-.75V7.91a.76.76 0 111.51 0v1.88a.76.76 0 01-.76.75z"
        ></path>
        <circle cx="15.01" cy="5.69" r="0.76" fill="#4285f4"></circle>
        <circle cx="15.01" cy="14.19" r="0.76" fill="#4285f4"></circle>
        <circle cx="15.01" cy="11.97" r="0.76" fill="#4285f4"></circle>
        <circle cx="8.99" cy="14.19" r="0.76" fill="#aecbfa"></circle>
        <circle cx="8.99" cy="7.92" r="0.76" fill="#aecbfa"></circle>
        <circle cx="8.99" cy="5.69" r="0.76" fill="#aecbfa"></circle>
        <path
          fill="#aecbfa"
          d="M9 12.73a.76.76 0 01-.76-.73v-1.9a.75.75 0 111.5 0V12a.75.75 0 01-.74.73z"
        ></path>
      </svg>,
      "Create a customer service chatbot that provides using OpenAI ChatGPT."
    ],
    [
      'faq',
      'FAQ',
      <></>,
      '',
    ]
  ]

export function Apps() {
  // const { account } = useOutletContext<{ account: any }>();
  return <>
    <Helmet title="Apps" />
    <h2 className="text-xl font-semibold text-heading">Apps</h2>
    <div className="mt-4">
      <div className="grid gap-4 xl:grid-cols-2">
        {APPS.map(([key, name, icon, description, soon]) => (
          <div
            key={key}
            className="rounded-xl bg-layer-2 px-6 py-4 hover:drop-shadow"
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-4">
                <div className="h-14 w-14 rounded-xl bg-layer-3 p-2">
                  {/* <FontAwesomeIcon className="h-10 w-10" icon={icon} /> */}
                  {icon}
                </div>
                <h3 className="text-base font-semibold text-heading">
                  {name}
                </h3>
              </div>
              <div>
                {soon ? (
                  <span className="text-sm">Coming soon</span>
                ) : (
                  <Link
                    to={key}
                    className="inline-flex cursor-pointer items-center justify-center rounded-xl border-2 border-primary bg-primary px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:border-primary-accent hover:bg-primary-accent focus:outline-none focus:ring-2 focus:ring-orange-400/80 focus:ring-offset-0 disabled:opacity-30 disabled:hover:border-primary disabled:hover:bg-primary disabled:hover:text-white dark:focus:ring-white/80"
                  >
                    Connect
                  </Link>
                )}
              </div>
            </div>
            <div className="mt-4">{description}</div>
          </div>
        ))}
      </div>
    </div >
  </>
}

const AppsRoute: RouteObject = {
  id: "apps",
  path: "apps",
  children: [
    {
      index: true,
      element: <Apps />,
    },
    AIAgentAppRoute,
    FAQAppRoute,
    // MetaIntegrationRoute,
    // FacebookIntegrationRoute,
    // InstagramIntegrationRoute,
    // WhatsAppIntegrationRoute,
  ],
};

export default AppsRoute;