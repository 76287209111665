import { collection, doc, getDoc, getFirestore, query } from "firebase/firestore";
import { Suspense } from "react";
import { Helmet } from "react-helmet-async";
import { Await, LoaderFunctionArgs, RouteObject, defer, useLoaderData, useOutletContext } from "react-router-dom";
import { firestore } from "../../../../firebase";
import { useFirestoreQueryData } from "@react-query-firebase/firestore";

function Option({ name, title, description, disabled }: { name: string; title: string; description?: string, disabled?: boolean }) {
  return (
    <div className="flex items-center justify-between py-4">
      <div className="flex flex-col flex-grow">
        <div className="text-lg font-semibold text-gray-900 dark:text-white">{title}</div>
        <div className="text-base font-normal text-gray-500 dark:text-gray-400">{description}</div>
      </div>
      <label className="relative flex items-center cursor-pointer">
        <input name={name} type="checkbox" className="sr-only" disabled={disabled} />
        <span className="h-6 bg-gray-200 border border-gray-200 rounded-full w-11 toggle-bg dark:bg-gray-700 dark:border-gray-600"></span>
      </label>
    </div>
  );
}

const APP_KEY = 'faq'

type QA = {
  id?: string
  question: string
  answer: string
}

// function QAForm() {
//   const { account } = useOutletContext<any>();
//   const $collection = collection(
//     firestore,
//     "accounts",
//     account.id,
//     "apps",
//     APP_KEY,
//     "qas"
//   ).withConverter<QA>({
//     toFirestore(value) {
//       return value as any
//     },
//     fromFirestore(snapshot, options) {
//       return {
//         id: snapshot.id,
//         ...snapshot.data(),
//       } as QA
//     }
//   })
//   const mutation = useFirestoreCollectionMutation($collection);
//   return (<form onSubmit={e => {
//     e.preventDefault()
//     mutation.mutate({ question: 'Quesion ', answer: '' })
//   }} className="p-3 mb-4 bg-white rounded-lg shadow-sm sm:p-6 dark:bg-layer-2 xl:mb-0 flex flex-col space-y-4">
//     <div className="flow-root">
//       <h3 className="text-xl font-semibold dark:text-white mb-3">Add new Question/Answer</h3>
//       <div className="mb-2">
//         <label
//           htmlFor="question"
//           className="block text-sm font-semibold text-heading"
//         >
//           Question
//         </label>
//         <input
//           id="question"
//           name="question"
//           type="text"
//           className="mt-2 block w-full rounded-xl border-2 border-muted-3 bg-transparent px-4 py-2.5 font-semibold text-heading placeholder:text-text/50 focus:border-primary focus:outline-none focus:ring-0 sm:text-sm"
//         />
//       </div>
//       <div>
//         <label
//           htmlFor="answer"
//           className="block text-sm font-semibold text-heading"
//         >
//           Answer
//         </label>
//         <textarea
//           id="answer"
//           name="answer"
//           rows={3}
//           className="mt-2 block w-full rounded-xl border-2 border-muted-3 bg-transparent px-4 py-2.5 font-semibold text-heading placeholder:text-text/50 focus:border-primary focus:outline-none focus:ring-0 sm:text-sm"
//         />
//       </div>
//     </div>
//     <button
//       type="submit"
//       className="inline-flex cursor-pointer items-center justify-center rounded-xl border-2 border-primary bg-primary px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:border-primary-accent hover:bg-primary-accent focus:outline-none focus:ring-2 focus:ring-orange-400/80 focus:ring-offset-0 disabled:opacity-30 disabled:hover:border-primary disabled:hover:bg-primary disabled:hover:text-white dark:focus:ring-white/80"
//     >
//       Add
//     </button>
//   </form>)
// }

function QAs() {
  const { account } = useOutletContext<any>();
  const $collection = collection(
    firestore,
    "accounts",
    account.id,
    "apps",
    APP_KEY,
    "qas"
  ).withConverter<QA>({
    toFirestore(value) {
      return value as any
    },
    fromFirestore(snapshot, options) {
      return {
        id: snapshot.id,
        ...snapshot.data(),
      } as QA
    }
  })
  const { data, isLoading } = useFirestoreQueryData<QA>(
    ['faqs'],
    query(
      $collection,
    ),
    { subscribe: true }
  )
  if (isLoading) return <div role="status" className="p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700">
    {
      new Array(5).fill(undefined).map((_, i) => (
        <div key={i} className={["flex items-center justify-between", i && "pt-4"].filter(Boolean).join(' ')}>
          <div className="flex-1">
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700" style={{ width: Math.floor(8 + Math.random() * (88 - 8 + 8)) + '%' }}></div>
          </div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
        </div>
      ))
    }
  </div>


  return (
    <>
      {/* <pre>{JSON.stringify({ isLoading, isSuccess, error }, null, 2)}</pre> */}
      <div role="status" className="space-y-4 divide-y divide-gray-200 rounded shadow dark:divide-gray-700 dark:border-gray-700">
        {
          data?.map((qa, i) => (
            <div key={qa.id} className={["flex items-center justify-between", i && "pt-4"].filter(Boolean).join(' ')}>
              <div className="flex-1">
                <h1 className="text-xl font-semibold dark:text-white">{qa.question}</h1>
                <div className="">{qa.answer}</div>
              </div>
              <div className="">Edit</div>
            </div>
          ))
        }
      </div>
    </>
  )

}

export function FAQApp() {
  const deferedData = useLoaderData() as any;
  const { account } = useOutletContext<any>();
  const title = "Frequently Asked Question";

  return <>
    <Helmet title={title} />
    <h2 className="text-xl font-semibold text-heading">{title}</h2>
    <Suspense fallback="Loading App...">
      <Await resolve={deferedData.app}>
        {app =>
          // <Formik
          //   initialValues={app}
          //   onSubmit={(values, { setSubmitting }) => {
          //     setTimeout(() => {
          //       alert(JSON.stringify(values, null, 2));

          //     }, 400);
          //   }}
          // >
          // </Formik>
          <>
            <main className="mt-8 grid grid-cols-3 gap-5">
              <div className="col-span-2">
                <QAs />
              </div>
              <div>
                <div className="p-4 mb-4 bg-white rounded-lg shadow-sm sm:p-6 dark:bg-layer-2 xl:mb-0">
                  <div className="flow-root">
                    <h3 className="text-xl font-semibold dark:text-white">Shared Knowledge Base</h3>
                    <p className="text-sm font-normal text-gray-500 dark:text-gray-400">Select the data you want to share with the AI Agent</p>
                    <div className="divide-y divide-gray-200 dark:divide-gray-700 mt-4">
                      <Option
                        name="faq"
                        title="Frequently Asked Questions"
                      // description="Get Themesberg news, announcements, and product updates"
                      />
                      <Option
                        name="products"
                        title="Store Products"
                        disabled={!account.shopify}
                      // description="Get Themesberg news, announcements, and product updates"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </>
        }
      </Await>
    </Suspense>
  </>;
}

const FAQAppRoute: RouteObject = {
  id: APP_KEY,
  path: APP_KEY,
  children: [
    {
      index: true,
      element: <FAQApp />,
      // async action({ request, params }) {
      //   const formData = await request.formData();
      //   const app = Object.fromEntries(formData);
      //   console.log('app', app)
      //   await setDoc(
      //     doc(getFirestore(), "accounts", params.account!, "apps", APP_KEY),
      //     app,
      //     {
      //       merge: true,
      //     }
      //   )
      //   console.log('Saved')
      //   return null
      // },
      loader({ params, request }: LoaderFunctionArgs) {
        return defer({
          app: getDoc(doc(getFirestore(), "accounts", params.account!, "apps", APP_KEY)).then(
            (snapshot) => ({ id: snapshot.id, ...snapshot.data() })
          ),
        });
      },
    },
  ],
};

export default FAQAppRoute;
