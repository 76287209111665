import { doc, getDoc, getFirestore } from "firebase/firestore";
import { Helmet } from "react-helmet-async";
import {
  LoaderFunctionArgs,
  Outlet,
  RouteObject,
  useOutletContext,
  useRouteLoaderData,
} from "react-router-dom";
import MessagesRoute from "./messages";
import { useEffect } from "react";

export function Conversation() {
  const conversation: any = useRouteLoaderData("conversation");
  const { account } = useOutletContext() as any;
  useEffect(() => {
    // httpsCallable("integrations-shopify-conversation")({
    //   account: account.id,
    //   conversation: conversation.id,
    //   action: 'identify'
    // }).then(console.log)
    // integrations-shopify-conversation
  }, [conversation]);
  return (
    <>
      <Helmet title={conversation.title} />
      <Outlet context={{ account, conversation }} />
    </>
  );
}

const ConversationRoute: RouteObject = {
  id: "conversation",
  path: ":conversation",
  element: <Conversation />,
  async loader({ params }: LoaderFunctionArgs) {
    return getDoc(
      doc(
        getFirestore(),
        "accounts",
        params.account!,
        "conversations",
        params.conversation!
      )
    ).then((snapshot) => ({
      id: snapshot.id,
      ...snapshot.data(),
      source: snapshot.metadata,
    }));
  },
  children: [MessagesRoute],
};

export default ConversationRoute;
