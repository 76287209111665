import { useEffect, useState } from "react";
import { Form, RouteObject, redirect, useNavigate, useOutletContext } from "react-router-dom";
import httpsCallable from "../../../../utils/httpsCallable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShopify
} from "@fortawesome/free-brands-svg-icons";

export function callable(account: string, action: string, data: any = {}) {
  return httpsCallable<any, any>("integrations-shopify-oauth")({
    account,
    action,
    ...data
  }).then(
    result => result.data
  )
}

export function ShopifyIntegrationConnect() {
  const { account } = useOutletContext<any>();
  const object = "shopify";

  const [selected] = useState<string>(account[object] || "");

  return (
    <>
      <div className="relative mx-auto flex max-w-6xl flex-col items-center rounded-3xl bg-layer-2 py-8 px-6 text-center sm:py-12">
        <div className="mx-auto">
          <FontAwesomeIcon className="h-28 w-28" icon={faShopify} />
          <br />
          <span className="text-sm font-semibold uppercase tracking-wide">
            Shopify
          </span>
          <h2 className="mt-6 text-3xl font-semibold text-heading md:mt-8 md:text-4xl md:leading-tight">
            Do things quickly and easily.
          </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg font-medium md:mt-8">
            Quickly manage orders right from the conversation. No need to switch between apps.
          </p>
          <Form
            // action="."
            method="post"
            className="mx-auto w-full max-w-md"
          >
            <input type="hidden" name="object" value={object} />
            {selected && <input type="hidden" name="store" value={selected} />}

            <p className="text-xs mt-4">
              If you don't see the store on the list,
              <br />
              <button type="submit" className="text-blue-900 underline" name="action" value="reconnect">
                click here
              </button>{" "}
              to go to Shopify and select it again.
            </p>
            <button
              // disabled={(!!stores?.length && !selected) || isLoading}
              type="submit"
              //   onClick={onConnectClick}
              className="mt-6 inline-flex cursor-pointer items-center justify-center rounded-xl border-2 border-primary bg-primary px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:border-primary-accent hover:bg-primary-accent focus:outline-none focus:ring-2 focus:ring-orange-400/80 focus:ring-offset-0 disabled:opacity-30 disabled:hover:border-primary disabled:hover:bg-primary disabled:hover:text-white dark:focus:ring-white/80 md:mt-8"
            >
              Connect
              {/* {selectedStore?.name} */}
            </button>
          </Form>
        </div>
      </div>
    </>
  );
}

function ShopifyIntegration() {
  const { account } = useOutletContext<any>();
  const navigate = useNavigate();
  useEffect(() => {
    navigate(account?.shopify ? "options" : "connect", { replace: true });
  }, []);
  return <></>;
}

const ShopifyIntegrationRoute: RouteObject = {
  id: "shopify",
  path: "shopify",
  children: [
    {
      index: true,
      element: <ShopifyIntegration />,
      async loader({ request, params }) {
        const url = new URL(request.url);
        if (url.searchParams.has("code")) {
          const code = url.searchParams.get("code");
          return callable(params.account!, 'grant', {
            code,
            account: params.account,
            state: url.searchParams.get("state"),
          })
            .then(() => redirect(url.pathname))
        }
        return true;
      },
    },
    {
      path: "connect",
      async action({ request, params }) {
        const formData = await request.formData();
        console.log(formData, params)
        if (formData.get("action") === "reconnect" || !formData.has("store"))
          return callable(params.account!, 'request', {
            shop: 'oudytest2.myshopify.com',
          })
            .then((results) => redirect(results.url));
        return null;
      },
      element: <ShopifyIntegrationConnect />,
    },
    {
      path: "options",
      element: <>Shopify Options</>,
    },
  ],
};

export default ShopifyIntegrationRoute;