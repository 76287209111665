import { doc, getDoc, getFirestore } from "firebase/firestore";
import { Helmet } from "react-helmet-async";
import {
  Await,
  defer,
  Link,
  LoaderFunctionArgs,
  NavLink,
  Outlet,
  RouteObject,
  useLoaderData,
  useNavigate,
  useOutletContext,
  useParams
} from "react-router-dom";
import ConversationsRoute from "./conversations";
import {
  HomeIcon,
  // UsersIcon,
  Cog6ToothIcon,
  ArrowRightOnRectangleIcon,
  // Bars3CenterLeftIcon,
  // MagnifyingGlassIcon,
  BellIcon,
  MoonIcon,
  ChatBubbleLeftRightIcon,
} from "@heroicons/react/24/outline";
import { Suspense, useEffect, useState } from "react";
import classNames from "classnames";
import SettingsRoute from "./settings";
import Splash from "../../Splash";
import { getDatabase, onValue, ref } from "firebase/database";
import { INTEGRATIONS } from "./settings/integrations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function Account() {
  const deferedData = useLoaderData() as any;
  const [unAnsweredCount, setUnAnsweredCount] = useState(0)
  const params = useParams()
  useEffect(() => onValue(
    ref(
      getDatabase(),
      `/accounts/${params.account}/conversations/unanswered`
    ),
    snapshot => {
      setUnAnsweredCount(snapshot.val())
    }
  ), [params])
  return (
    <Suspense fallback={<Splash message="Loading Account" />}>
      <Await resolve={deferedData.account}>
        {(account) => {
          const accountLink = (...paths: string[]) =>
            [""].concat(paths).filter(Boolean).join("/");
          return (
            <>
              <Helmet titleTemplate={`%s - ${account.name} - reqas`} title="Home" />
              <div className="flex h-screen">
                <div className="flex w-screen flex-1">
                  <div className="hidden group/sidebar flex-shrink-0 md:block">
                    <div className="relative z-30 flex h-full flex-col bg-layer-2 shadow">
                      <div className="flex flex-1 flex-col overflow-y-auto">
                        <div className="mt-5 h-8 flex-shrink-0 px-4 text-heading">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="100"
                            viewBox="0 0 628.09 655.83"
                            className="w-6"
                          >
                            <path
                              fill="#fff"
                              d="M580.04 655.84H433.16L280.91 436.92H162.09l-38.6 218.93H.04L115.64 0c103.85 0 207.53.94 311.38.94 154.2.94 217.45 103.85 197.49 217.05-15.84 89.82-73 180.56-201.89 205.83l158.91 223.6zM218.68 116.01l-37 209.57h188.09c78.59 0 121.51-52.39 130.75-104.78s-16.14-104.79-93.8-104.79z"
                            ></path>
                          </svg>
                          {/* <span className="h-full"></span> */}
                        </div>
                        <div className="mt-5 space-y-1 px-1 sm:px-2">
                          <NavLink
                            end
                            to={accountLink()}
                            className={({ isActive }) =>
                              classNames(
                                "group relative flex items-center rounded-xl px-2 py-2 font-medium focus:outline-none focus:ring-2 focus:ring-heading/80",
                                {
                                  "bg-layer-3 text-heading": isActive,
                                  "text-text hover:bg-layer-3 hover:text-heading":
                                    !isActive,
                                }
                              )
                            }
                          >
                            <HomeIcon className="h-6 w-6 flex-shrink-0" />
                            <span className="flex-1 font-semibold group-hover/sidebar:ml-3 transition-width hover:duration-1000 duration-300 max-w-0 opacity-0 group-hover/sidebar:opacity-100 group-hover/sidebar:max-w-[1000px]">
                              Home
                            </span>
                          </NavLink>
                          <NavLink
                            to={accountLink("conversations")}
                            className={({ isActive }) =>
                              classNames(
                                "group relative flex items-center rounded-xl px-2 py-2 font-medium focus:outline-none focus:ring-2 focus:ring-heading/80",
                                {
                                  "bg-layer-3 text-heading": isActive,
                                  "text-text hover:bg-layer-3 hover:text-heading":
                                    !isActive,
                                }
                              )
                            }
                          >
                            <div className="relative">
                              <ChatBubbleLeftRightIcon className="h-6 w-6 flex-shrink-0" />
                              <span className={
                                classNames(
                                  'absolute right-0 top-0 inline-block translate-x-2 -translate-y-2 rounded-lg bg-primary py-0.5 px-1 text-xs font-semibold text-white',
                                  {
                                    'invisible': !unAnsweredCount
                                  }
                                )
                              }>
                                {unAnsweredCount}
                              </span>
                            </div>
                            <span className="flex-1 font-semibold group-hover/sidebar:ml-3 transition-width hover:duration-1000 duration-300 max-w-0 opacity-0 group-hover/sidebar:opacity-100 group-hover/sidebar:max-w-[1000px]">
                              Conversations
                            </span>
                          </NavLink>
                          {/* <NavLink
                            to={accountLink("customers")}
                            className={({ isActive }) =>
                              classNames(
                                "group relative flex items-center rounded-xl px-2 py-2 font-medium focus:outline-none focus:ring-2 focus:ring-heading/80",
                                {
                                  "bg-layer-3 text-heading": isActive,
                                  "text-text hover:bg-layer-3 hover:text-heading":
                                    !isActive,
                                }
                              )
                            }
                          >
                            <UsersIcon className="h-6 w-6 flex-shrink-0" />
                            <span className="flex-1 font-semibold group-hover/sidebar:ml-3 transition-width hover:duration-1000 duration-300 max-w-0 opacity-0 group-hover/sidebar:opacity-100 group-hover/sidebar:max-w-[1000px]">
                              Customers
                            </span>
                          </NavLink> */}
                        </div>
                      </div>
                      <div className="mb-2 space-y-1 px-1 sm:px-2">
                        <NavLink
                          to={accountLink("settings")}
                          className="text-text hover:bg-layer-3 hover:text-heading group relative flex items-center rounded-xl px-2 py-2 font-medium focus:outline-none focus:ring-2 focus:ring-heading/80"
                        >
                          <Cog6ToothIcon className="h-6 w-6 flex-shrink-0" />
                          <span className="flex-1 font-semibold group-hover/sidebar:ml-3 transition-width hover:duration-1000 duration-300 max-w-0 opacity-0 group-hover/sidebar:opacity-100 group-hover/sidebar:max-w-[1000px]">
                            Settings
                          </span>
                        </NavLink>
                        <Link
                          to={"/logout"}
                          className="text-text hover:bg-layer-3 hover:text-heading group relative flex items-center rounded-xl px-2 py-2 font-medium focus:outline-none focus:ring-2 focus:ring-heading/80"
                        >
                          <ArrowRightOnRectangleIcon className="h-6 w-6 flex-shrink-0" />
                          <span className="flex-1 font-semibold group-hover/sidebar:ml-3 transition-width hover:duration-1000 duration-300 max-w-0 opacity-0 group-hover/sidebar:opacity-100 group-hover/sidebar:max-w-[1000px]">
                            Logout
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="relative w-full flex-1 md:overflow-hidden">
                    <nav className="hidden relative z-20 flex h-16 flex-1 shrink-0 items-center space-x-2 bg-layer-2 px-4 shadow sm:px-6">
                      {/* <div className="flex items-center space-x-2 md:hidden">
                        <button
                          type="button"
                          className="inline-flex cursor-pointer items-center justify-center rounded-xl border-none border-transparent bg-transparent p-2.5 font-semibold text-text hover:bg-heading/5 hover:text-heading focus:bg-heading/5 focus:outline-none focus:ring-2 focus:ring-heading/80 focus:ring-offset-0 disabled:opacity-30 disabled:hover:bg-transparent disabled:hover:text-text"
                        >
                          <Bars3CenterLeftIcon className="h-6 w-6" />
                        </button>
                        <div className="h-7 text-heading">
                          <span className="h-full">LOGO</span>
                        </div>
                      </div> */}
                      <div className="flex flex-1 space-x-1.5">
                        {/* <div className="hidden md:block">
                          <div>
                            <label
                              htmlFor="Search"
                              className="sr-only block text-sm font-semibold text-heading"
                            >
                              Search
                            </label>
                            <div className="relative flex">
                              <div className="pointer-events-none absolute inset-y-0 left-0 flex flex-shrink-0 items-center pl-4 focus-within:z-20">
                                <MagnifyingGlassIcon className="h-5 w-5 text-text" />
                              </div>
                              <input
                                id="price"
                                name="price"
                                placeholder="Search"
                                className="block w-full rounded-xl border-2 border-layer-3 bg-muted-1 px-4 py-2.5 pl-11 pr-14 font-semibold text-heading placeholder:text-text/50 focus:border-primary focus:outline-none focus:ring-0 sm:text-sm"
                              />
                              <div className="pointer-events-none absolute inset-y-0 right-0 flex flex-shrink-0 items-center pr-4 focus-within:z-20">
                                <kbd className="rounded-md bg-muted-3 px-2 py-1 font-sans text-sm text-text">
                                  ⌘ K
                                </kbd>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                      <div className="flex flex-shrink-0 items-center space-x-3">
                        <button
                          type="button"
                          className="inline-flex hidden cursor-pointer items-center justify-center rounded-xl border-none border-transparent bg-transparent p-2.5 font-semibold text-text hover:bg-heading/5 hover:text-heading focus:bg-heading/5 focus:outline-none focus:ring-2 focus:ring-heading/80 focus:ring-offset-0 disabled:opacity-30 disabled:hover:bg-transparent disabled:hover:text-text sm:flex"
                        >
                          <BellIcon className="h-6 w-6" />
                        </button>
                        <button
                          type="button"
                          className="inline-flex hidden cursor-pointer items-center justify-center rounded-xl border-none border-transparent bg-transparent p-2.5 font-semibold text-text hover:bg-heading/5 hover:text-heading focus:bg-heading/5 focus:outline-none focus:ring-2 focus:ring-heading/80 focus:ring-offset-0 disabled:opacity-30 disabled:hover:bg-transparent disabled:hover:text-text sm:flex"
                        >
                          <MoonIcon className="h-6 w-6" />
                        </button>
                        <div className="relative">
                          <button
                            type="button"
                            id="headlessui-menu-button-:r0:"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <img
                              src={`https://api.dicebear.com/5.x/initials/svg?seed=${encodeURIComponent(
                                account.name
                              )}`}
                              alt={account.name}
                              className="inline-block h-8 w-8 rounded-full"
                            />
                          </button>
                        </div>
                      </div>
                    </nav>
                    <div className="md:absolute md:inset-x-0 md:top-0 md:bottom-0">
                      <div className="h-full scrollbar md:overflow-y-auto">
                        <Outlet context={{ account }} />
                        {/* <div className="h-96 rounded-xl border-4 border-dashed border-muted-1" /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </Await>
    </Suspense>
  );
}

function AccountIndex() {
  const { account } = useOutletContext<any>();
  const navigate = useNavigate()
  useEffect(() => {
    if ([account.facebook, account.instagram, account.whatsapp].find(Boolean))
      navigate(`/account/${account.id}/conversations`)

  }, [])
  return (
    <>
      <Helmet title="Home" />
      <section className="py-8 px-6 sm:py-18">
        <div className="mx-auto flex max-w-3xl flex-col items-center text-center">
          <h2 className="text-4xl font-semibold text-heading md:text-5xl md:leading-tight">
            There will be a Dashboard here
          </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg font-medium">
            First you need to authorize access and your pages and accounts
          </p>
          <div className="mt-8 flex flex-wrap justify-center gap-4 sm:mt-12 md:gap-8">
            {INTEGRATIONS.map(
              ([integration, , icon]) => (
                <div key={integration} className="grid h-16 w-16 place-items-center rounded-2xl bg-layer-2 p-2">
                  <FontAwesomeIcon className="h-10 w-10" icon={icon} />
                </div>
              )
            )}
          </div>
          <Link
            to="./settings/integrations"
            className="mt-8 inline-flex cursor-pointer items-center justify-center rounded-xl border-2 border-primary bg-primary px-4 py-2.5 text-base font-semibold text-white shadow-sm hover:border-primary-accent hover:bg-primary-accent focus:outline-none focus:ring-2 focus:ring-orange-400/80 focus:ring-offset-0 disabled:opacity-30 disabled:hover:border-primary disabled:hover:bg-primary disabled:hover:text-white dark:focus:ring-white/80 sm:mt-12"
          >
            See all integrations
          </Link>
        </div>
      </section>
    </>
  );
}

const AccountRoute: RouteObject = {
  path: "account/:account",
  element: <Account />,
  id: "account",
  loader({ params }: LoaderFunctionArgs) {
    return defer({
      account: getDoc(doc(getFirestore(), "accounts", params.account!)).then(
        (snapshot) => ({ id: snapshot.id, ...snapshot.data() })
      ),
    });
  },
  children: [
    {
      index: true,
      element: <AccountIndex />,
    },
    ConversationsRoute,
    SettingsRoute,
  ],
};

export default AccountRoute;
