import { getAuth } from "firebase/auth";
import {
  where,
  documentId,
  collection,
  getFirestore,
  query,
  getDocs,
} from "firebase/firestore";
import { Await, defer, Link, RouteObject, useLoaderData, useNavigate } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { Suspense, useEffect } from "react";
import Splash from "../../Splash";
import { Helmet } from "react-helmet-async";

export function Select() {
  const deferedData = useLoaderData() as any;
  const navigate = useNavigate()

  useEffect(() => {
    deferedData?.accounts?.then(
      (accounts: any[]) => {
        if (accounts?.length === 1)
          navigate(`/account/${accounts[0].id}`)
        if (!accounts.length) {
          navigate("/account/create");
        }
      }
    )
  }, [deferedData])

  return (
    <Suspense fallback={<Splash message="Loading Accounts" />}>
      <Await resolve={deferedData.accounts}>
        {(accounts) => {
          return <>
            <Helmet title="Select account" />
            <div className="min-h-full px-4 py-8">
              <div className="mx-auto flex max-w-3xl flex-col items-center justify-start space-y-4 sm:flex-row sm:items-end sm:justify-around sm:space-y-0">
                <div className="w-full max-w-xs divide-y divide-layer-3">
                  {accounts.map((account: any) => (
                    <Link
                      to={`/account/${account.id}`}
                      key={account.id}
                      className="flex items-center justify-between space-x-4 py-4"
                    >
                      <img
                        src={`https://api.dicebear.com/5.x/initials/svg?seed=${encodeURIComponent(
                          account.name
                        )}`}
                        alt={account.name}
                        className="h-14 w-14 shrink-0 rounded-xl"
                      />
                      <h4 className="flex-1 text-base font-semibold text-heading">
                        {account.name}
                      </h4>
                      <p className="inline-flex cursor-pointer items-center justify-center rounded-xl border-none border-transparent bg-transparent p-2 font-semibold text-text hover:bg-heading/5 hover:text-heading focus:bg-heading/5 focus:outline-none focus:ring-2 focus:ring-heading/80 focus:ring-offset-0 disabled:opacity-30 disabled:hover:bg-transparent disabled:hover:text-text">
                        <span className="sr-only">
                          Select ${account.name}'s profile
                        </span>
                        <ChevronRightIcon className="h-5 w-5" />
                      </p>
                    </Link>
                  ))}
                </div>
              </div>
            </div></>
        }}
      </Await>
    </Suspense>
  )
}

const AccountSelectRoute: RouteObject = {
  path: "account/select",
  element: <Select />,
  loader() {
    return defer({
      accounts: getAuth()
        .currentUser?.getIdTokenResult()
        .then((token) => Object.keys(token.claims.accounts || {}))
        .then(async (accounts) => {
          if (!accounts.length) {
            return accounts
          }
          return getDocs(
            query(
              collection(getFirestore(), "accounts"),
              where(documentId(), "in", accounts)
            )
          )
            .then((snapshot) =>
              snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
            )
            .then((accounts) => {
              return accounts;
            });
        })
    })
  },
};

export default AccountSelectRoute;
