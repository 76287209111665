import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { useEffect } from "react";
import {
  RouteObject,
  useNavigate,
  useOutletContext
} from "react-router-dom";
import { MetaIntegrationConnect } from "./meta-connect";
import { MetaIntegrationDisconnect } from "./meta-disconnect";

const title = 'WhatsApp Business'

export function WhatsAppIntegrationConnect() {
  return <MetaIntegrationConnect
    object="whatsapp"
    icon={faWhatsapp}
    title={title}
    subtitle="Make Every Conversation Count Via WhatsApp"
    description="Provide always-on and personalized support and turn conversations into revenue on one of the most popular messaging channels."
  />
}

function WhatsAppIntegration() {
  const { account } = useOutletContext<any>();
  const navigate = useNavigate();
  useEffect(() => {
    navigate(account?.whatsapp ? "options" : "connect", { replace: true });
  }, [account]);
  return <></>;
}

const WhatsAppIntegrationRoute: RouteObject = {
  id: "whatsapp",
  path: "whatsapp",
  children: [
    {
      index: true,
      element: <WhatsAppIntegration />,
    },
    {
      path: "connect",
      element: <WhatsAppIntegrationConnect />,
    },
    {
      path: "options",
      element: <MetaIntegrationDisconnect object="whatsapp" icon={faWhatsapp} title={title} />,
    },
  ],
};

export default WhatsAppIntegrationRoute;
