import {
  Await,
  Navigate,
  redirect,
  RouteObject,
  useLoaderData
} from "react-router-dom";
import httpsCallable from "../../../../utils/httpsCallable";
import Splash from "../../../../Splash";
import { Suspense } from "react";

function MetaIntegration() {
  const defered = useLoaderData() as any;
  return <Suspense fallback={<Splash message="Configuring...." fullScreen={false} />}>
    <Await resolve={defered.loader}>{url => <Navigate to={url} replace={true} />}</Await>
  </Suspense>;
}

const MetaIntegrationRoute: RouteObject = {
  id: "meta",
  path: "meta",
  element: <MetaIntegration />,
  async action({ request, params }) {
    const { account } = params;
    const url = new URL(request.url);
    const formData = await request.formData();
    const action = formData.get("action");
    const object = formData.get("object");
    if (action === "request")
      return httpsCallable<any, any>("integrations-meta-oauth")({
        action,
        account,
        object,
      }).then((results) => redirect(results.data.url));
    if (action === "subscribe")
      return httpsCallable<any, any>("integrations-meta-oauth")({
        action,
        account,
        object,
        page: formData.get("page")!,
      })
        .then(() => redirect(url.pathname.replace('meta', object?.toString()!)))
    if (action === "unsubscribe")
      return httpsCallable<any, any>("integrations-meta-oauth")({
        action,
        account,
        object,
      })
        .then(() => redirect(url.pathname.replace('meta', object?.toString()!)))
  },
  async loader({ request, params }) {
    const url = new URL(request.url);
    if (url.searchParams.has("code")) {
      const code = url.searchParams.get("code");
      return {
        loader: httpsCallable<any, any>("integrations-meta-oauth")({
          action: 'grant',
          code,
          account: params.account,
          state: url.searchParams.get("state"),
        })
          .then(() => url.pathname.replace('meta', url.searchParams.get("object")!))
      }
    }
    return { loader: true };
  },
};

export default MetaIntegrationRoute;
