import { Fragment, useState } from "react";
import { useQuery } from "react-query";
import { useFetcher, useOutletContext } from "react-router-dom";
import httpsCallable from "../../../../utils/httpsCallable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";

export function MetaIntegrationConnect(
  { object, icon, title, subtitle, description }:
    { object: string, icon: IconProp, title: string, subtitle: string, description: string }
) {
  const { account } = useOutletContext<any>();

  const [selected, setSelected] = useState<string>(account[object] || "");

  const {
    isFetching: isLoading,
    data: pages,
  } = useQuery<any[], Error>(
    ["integrations-meta-accounts", object, account.id],
    () =>
      httpsCallable<any, any>("integrations-meta-oauth")({
        action: "accounts",
        account: account.id,
        object,
      }).then((result) => {
        if (result.data.length === 1) {
          setSelected(result.data[0].id);
        }
        return result.data;
      }),
    {
      enabled: !!account?.integrations?.[object],
    }
  );


  const selectedPage = pages?.find((page) => page.id === selected);
  const fetcher = useFetcher();
  return (
    <>
      <div className="relative mx-auto flex max-w-6xl flex-col items-center rounded-3xl bg-layer-2 py-8 px-6 text-center sm:py-12">
        <div className="mx-auto">
          <FontAwesomeIcon className="h-28 w-28" icon={icon} />
          <br />
          <span className="text-sm font-semibold uppercase tracking-wide">
            {title}
          </span>
          <h2 className="mt-6 text-3xl font-semibold text-heading md:mt-8 md:text-4xl md:leading-tight">
            {subtitle}
          </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg font-medium md:mt-8">
            {description}
          </p>
          <fetcher.Form
            action="../../meta"
            method="post"
            className="mx-auto w-full max-w-md"
          >
            <input type="hidden" name="object" value={object} />
            {selected && <input type="hidden" name="page" value={selected} />}
            {(pages?.length || isLoading) && (
              <Listbox
                value={selected}
                onChange={setSelected}
                disabled={isLoading}
              >
                <div className="relative mt-6 text-left">
                  <Listbox.Button className=" text-left relative w-full cursor-default rounded-lg bg-layer-1 py-2 pl-3 pr-10 shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                    <div className="flex items-center justify-between space-x-4 py-2">
                      {isLoading ? (
                        <svg
                          aria-hidden="true"
                          className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                      ) : (
                        selectedPage?.picture && <img
                          src={selectedPage?.picture}
                          alt={selectedPage?.name}
                          className="h-10 w-10 shrink-0 rounded-xl"
                        />
                      )}
                      <h4
                        className={classNames(
                          "flex-1 text-sm font-semibold text-heading"
                        )}
                      >
                        {selectedPage?.name ||
                          (isLoading ? "Loading..." : "Select Page")}
                      </h4>
                    </div>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-30 mt-1 max-h-60 w-full overflow-auto rounded-md bg-layer-1 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {pages?.map((page, personIdx) => (
                        <Listbox.Option
                          key={personIdx}
                          className={({ active }) =>
                            `relative cursor-default select-none py-2 pl-4 pr-12 ${active
                              ? "bg-layer-2 text-secondary"
                              : "text-secondary-accent"
                            }`
                          }
                          value={page.id}
                        >
                          {({ selected }) => (
                            <>
                              <div className="flex items-center justify-between space-x-4 py-2">
                                {page.picture ? <img
                                  src={page.picture}
                                  alt={page.name}
                                  className="h-10 w-10 shrink-0 rounded-xl"
                                /> : <div className="h-10 w-10 shrink-0 rounded-xl bg-text"></div>}
                                <h4
                                  className={classNames(
                                    "flex-1 text-sm font-semibold text-heading",
                                    selected ? "font-medium" : "font-normal"
                                  )}
                                >
                                  {page.name || page.username}
                                </h4>
                                <span className="text-xs">{page?.phones}</span>
                              </div>
                              {selected && (
                                <span className="absolute inset-y-0 right-0 flex items-center pr-3 text-amber-600">
                                  <CheckIcon
                                    className="h-8 w-8"
                                    aria-hidden="true"
                                  />
                                </span>
                              )}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
            )}
            {pages?.length && (
              <p className="text-xs mt-4">
                If you don't see the account you want on the list,
                <br />
                <button type="submit" className="text-blue-900 underline" name="action" value="request">
                  click here
                </button>{" "}
                to go to Meta and select it again.
              </p>
            )}
            <button
              disabled={(!!pages?.length && !selected) || isLoading || fetcher.state === "submitting"}
              type="submit"
              name="action"
              value={selected ? "subscribe" : "request"}
              className="mt-6 inline-flex cursor-pointer items-center justify-center rounded-xl border-2 border-primary bg-primary px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:border-primary-accent hover:bg-primary-accent focus:outline-none focus:ring-2 focus:ring-orange-400/80 focus:ring-offset-0 disabled:opacity-30 disabled:hover:border-primary disabled:hover:bg-primary disabled:hover:text-white dark:focus:ring-white/80 md:mt-8"
            >
              {fetcher.state === "submitting" && <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>} Connect{fetcher.state === "submitting" && 'ing'} {selectedPage?.name}
            </button>
          </fetcher.Form>
        </div>
      </div>
    </>
  );
}