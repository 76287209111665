import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { useEffect } from "react";
import {
  RouteObject,
  useNavigate,
  useOutletContext
} from "react-router-dom";
import { MetaIntegrationConnect } from "./meta-connect";
import { MetaIntegrationDisconnect } from "./meta-disconnect";

const title = "Instagram Messaging"

export function InstagramIntegrationConnect() {
  return <MetaIntegrationConnect
    object="instagram"
    icon={faInstagram}
    title={title}
    subtitle="Connect and Engage With Customers on Instagram."
    description="Connect and engage with the Instagram community: have meaningful conversations, increase customer satisfaction, drive sales."
  />
}

function InstagramIntegration() {
  const { account } = useOutletContext<any>();
  const navigate = useNavigate();
  useEffect(() => {
    navigate(account?.instagram ? "options" : "connect", { replace: true });
  }, [account]);
  return <></>;
}

const InstagramIntegrationRoute: RouteObject = {
  id: "instagram",
  path: "instagram",
  children: [
    {
      index: true,
      element: <InstagramIntegration />,
    },
    {
      path: "connect",
      element: <InstagramIntegrationConnect />,
    },
    {
      path: "options",
      element: <MetaIntegrationDisconnect object="instagram" icon={faInstagram} title={title} />,
    },
  ],
};

export default InstagramIntegrationRoute;
