import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
// import { getFunctions } from "firebase/functions";
// import { getStorage } from "firebase/storage";
// import { getDatabase } from "firebase/database";

const config = {
  apiKey: "AIzaSyA08drA0mdJoCo33TRf93edAYmD5u79PJU",
  authDomain: "reqas-io.firebaseapp.com",
  databaseURL: "https://reqas-io.firebaseio.com",
  projectId: "reqas-io",
  storageBucket: "gs://account-reqas-io",
  messagingSenderId: "90358511965",
  appId: "1:90358511965:web:3ae2a6451eaa739afad0b4",
  measurementId: "G-B5B84DWGD3"
};

export const app = initializeApp(config);
initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6LfoFrIjAAAAAPvb5xBNb2EpTSUHVXQp1QzPiXh4"),
  isTokenAutoRefreshEnabled: true,
});
export const auth =  getAuth(app);
export const firestore = getFirestore(app);
// getFunctions(app, "europe-west1");
// getStorage(app, 'gs://account-reqas-io');
// getDatabase(app);


// setAppLogLevel('debug')
// setFirestoreLogLevel('debug')

// if (process.env.NODE_ENV !== "local") {
//   // Set up emulators
//   try {
//     // setAppLogLevel('debug')
//     // setFirestoreLogLevel('debug')
//     connectAuthEmulator(auth, "http://localhost:9099");
//     connectFirestoreEmulator(firestore, "localhost", 8080);
// connectFunctionsEmulator(getFunctions(app, "europe-west1"), "localhost", 5001);
//   } catch (error) {
//     console.error(error)
//   }
// }

// enableMultiTabIndexedDbPersistence(firestore)
// try {
//   enableIndexedDbPersistence(firestore);
// } catch {}
