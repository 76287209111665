import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useFetcher, useOutletContext } from "react-router-dom";

export function MetaIntegrationDisconnect(
  { object, icon, title, subtitle, description }:
    { object: string, icon: IconProp, title?: string, subtitle?: string, description?: string }
) {
  const fetcher = useFetcher();
  const { account } = useOutletContext<any>();
  return (
    <fetcher.Form
      action="../../meta"
      method="post" className="rounded-xl bg-layer-2 px-6 py-4 hover:drop-shadow">
      <input type="hidden" name="object" value={object} />
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <div className="h-14 w-14 rounded-xl bg-layer-3 p-2">
            <FontAwesomeIcon className="h-10 w-10" icon={icon} />
          </div>
          <h3 className="text-base font-semibold text-heading">
            {title} ({account[object]})
          </h3>
        </div>
        <div>
          <button
            type="submit"
            name="action"
            value="unsubscribe"
            className="inline-flex cursor-pointer items-center justify-center rounded-xl border-2 border-critical bg-critical px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:border-critical-accent hover:bg-critical-accent focus:outline-none focus:ring-2 focus:ring-orange-400/80 focus:ring-offset-0 disabled:opacity-30 disabled:hover:border-critical disabled:hover:bg-critical disabled:hover:text-white dark:focus:ring-white/80"
          >
            {fetcher.state === "submitting" && <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>} Disconnect{fetcher.state === "submitting" && 'ing'}
          </button>
        </div>
      </div>
      <div className="mt-4">
        <p>reqas app is integrated with your {title}, you should see new messages in <Link to={`/account/${account.id}/conversations`} className="text-sm font-semibold text-primary hover:text-primary-accent">Conversations</Link></p>
        <p className="text-sm">If you want to disconnect your account, click the button above.</p>
      </div>
    </fetcher.Form>
  )
}