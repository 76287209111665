import {
  createBrowserRouter,
  Outlet,
  redirect,
  RouteObject,
  RouterProvider,
} from "react-router-dom";
import ErrorPage from "./pages/error";
import Index from "./pages";
import JoinRoute from "./pages/join";
import AccountSelectRoute from "./pages/account/select";
import AccountCreateRoute from "./pages/account/create";
import AccountRoute from "./pages/account/account";
import { getAuth } from "firebase/auth";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { QueryClient, QueryClientProvider } from "react-query";
import Splash from "./Splash";
import LogoutRoute from "./pages/account/logout";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      // suspense: true,
    },
  },
});
const routes: RouteObject[] = [
  {
    path: "/",
    element: <Outlet />,
    id: "root",
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Index /> },
      JoinRoute,
      LogoutRoute,
      {
        path: "",
        loader({ request }) {
          if (!getAuth().currentUser) {
            const url = new URL(request.url);
            const searchParams = new URLSearchParams();
            searchParams.set("return", url.pathname + url.search);
            throw redirect("/join?" + searchParams.toString());
          }
          return true;
        },
        children: [
          {
            path: "/account/settings/integrations/:provider",
            element: <Splash message="Loading App" />,
            loader({ params, request }) {
              const url = new URL(request.url);
              let account,
                object: string = params.provider!;
              if (url.searchParams.has("account")) {
                account = url.searchParams.get("account");
                url.searchParams.delete("account");
              } else if (url.searchParams.has("state")) {
                const payload = JSON.parse(
                  atob(url.searchParams.get("state")!.split(".")[1])
                );
                account = payload.account;
                if (payload.object) {
                  object = payload.object!;
                  url.searchParams.set("object", object);
                }
              }
              url.pathname = `/account/${account}/settings/integrations/${params.provider}`;
              return redirect(url.toString());
            },
          },
          AccountCreateRoute,
          {
            path: "",
            loader() {
              // check if account is authorized
              return true;
            },
            children: [AccountSelectRoute, AccountRoute],
          },
        ],
      },
    ],
  },
];

function App() {
  const router = createBrowserRouter(routes);
  return (
    <>
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          <Helmet titleTemplate="%s - reqas" />
          <RouterProvider router={router} />
        </QueryClientProvider>
      </HelmetProvider>
    </>
  );
}

export default App;
