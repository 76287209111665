import {
  EllipsisHorizontalIcon,
  // MagnifyingGlassIcon,
  // MoonIcon,
} from "@heroicons/react/24/outline";
import {
  collection,
  doc,
  getDoc,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import type { QueryConstraint } from "firebase/firestore";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  // Link,
  NavLink,
  Outlet,
  RouteObject,
  useOutletContext, useSearchParams
} from "react-router-dom";
import ConversationRoute from "./conversation";
import { RelativeTime } from "../../../components/RelativeTime";
import classNames from "classnames";
import type { Conversation } from "@reqasio/firebase/firestore/conversation";
import { Channel, MessageType } from "@reqasio/firebase/firestore";
import { useArray } from "../../../utils/useArray";
import {
  faFacebookMessenger,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Avatar from "./avatar";

const CHANNEL_ICON: any = {
  [Channel.facebook]: faFacebookMessenger,
  [Channel.instagram]: faInstagram,
  [Channel.whatsapp]: faWhatsapp,
};

function ConversationSnippet({ conversation }: { conversation: any }) {
  const { account } = useOutletContext() as any;
  const [message, setMessage] = useState<string>();
  useEffect(() => {
    if (conversation.snippet)
      getDoc(
        doc(
          getFirestore(),
          "accounts",
          account.id,
          "conversations",
          conversation.id,
          "messages",
          conversation.snippet
        )
      )
        .then((snapshot) => snapshot.data())
        .then((message) =>
          setMessage(
            message?.text || message?.event || MessageType[message?.type]
          )
        );
  }, [account.id, conversation.id, conversation.snippet]);
  return <>{message}</>;
}

export function Conversations() {
  const conversations = useArray<Conversation>([]);
  const [searchParams] = useSearchParams();
  const filter = useMemo(() => searchParams.get("filter"), [searchParams])
  const { account } = useOutletContext() as any;
  useEffect(() => {
    const queryConstraints: QueryConstraint[] = [orderBy("timestamp", "desc")];
    if (filter) {
      if (filter === 'unread')
        queryConstraints.unshift(
          where("answered", "==", false)
        );
    }
    const unsubscribe = onSnapshot(
      query(
        collection(getFirestore(), "accounts", account.id, "conversations"),
        ...queryConstraints
      ),
      (snapshot) =>
        snapshot
          .docChanges()
          .reverse()
          .forEach((change) => {
            const conversation = {
              id: change.doc.id,
              ...change.doc.data(),
            } as Conversation;
            conversations.removeById(conversation.id);
            if (change.type !== "removed") conversations.unshift(conversation);
          })
    );
    return () => {
      conversations.clear();
      return unsubscribe();
    };
  }, [account.id, filter]);
  const conversationLink = useCallback(
    (...paths: string[]) =>
      "/" +
      ["account", account.id, "conversations", ...paths]
        .filter(Boolean)
        .join("/"),
    [account.id]
  );

  return (
    <>
      <Helmet title="Conversations" />
      <div className="flex-1 h-full">
        <div className="flex h-full">
          <div className="w-full max-w-md bg-layer-2">
            <div className="z-10 flex h-full flex-col overflow-hidden">
              {/* <div className="mt-4 px-6">
                <div>
                  <label
                    htmlFor="Search"
                    className="sr-only block text-sm font-semibold text-heading"
                  >
                    Search
                  </label>
                  <div className="relative flex">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex flex-shrink-0 items-center pl-4 focus-within:z-20">
                      <MagnifyingGlassIcon className="h-5 w-5 text-text" />
                    </div>
                    <input
                      id="price"
                      name="price"
                      placeholder="Search"
                      className="block w-full rounded-xl border-2 border-layer-3 bg-muted-1 px-4 py-2.5 pl-11 pr-14 font-semibold text-heading placeholder:text-text/50 focus:border-primary focus:outline-none focus:ring-0 sm:text-sm"
                    />
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex flex-shrink-0 items-center pr-4 focus-within:z-20">
                      <kbd className="rounded-md bg-muted-3 px-2 py-1 font-sans text-sm text-text">
                        ⌘ K
                      </kbd>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div
                className="mt-4 flex px-6 pb-4"
                role="tablist"
                aria-orientation="horizontal"
              >
                {[
                  { label: "All", query: "" },
                  { label: "Unread", query: "filter=unread" },
                  { label: "Unresolved", query: "filter=unresolved" },
                ].map(({ label, query }, i) => (
                  <Link
                    key={i}
                    className={classNames(
                      "text-sm focus:outline-none capitalize px-2 border-2 py-1 rounded-full disabled:opacity-30 basis-full font-semibold focus:ring-offset-0 focus:ring-2 items-center inline-flex justify-center cursor-pointer",
                      {
                        "disabled:hover:text-text focus:ring-heading/80 focus:bg-heading/5 hover:text-heading border-transparent disabled:hover:bg-transparent hover:bg-heading/5 bg-transparent text-text":
                          query !== searchParams.toString(),
                        "hover:border-primary-accent text-white dark:focus:ring-white/80 focus:ring-orange-400/80 disabled:hover:text-white shadow-sm border-primary hover:bg-primary-accent disabled:hover:border-primary disabled:hover:bg-primary bg-primary":
                          query === searchParams.toString(),
                      }
                    )}
                    to={{ pathname: conversationLink(), search: query }}
                  >
                    {label}
                  </Link>
                ))}
              </div> */}
              <hr className="border-hr dark:border-muted-1" />
              <div className="flex-1 overflow-y-auto scrollbar scrollbar-thin">
                <div className="p-0.5 h-full">
                  {!!conversations.value.length && conversations.value.map((conversation) => (
                    <NavLink
                      key={conversation.id!}
                      to={{ pathname: conversationLink(conversation.id!), search: searchParams.toString() }}
                      className={({ isActive }) =>
                        classNames(
                          "relative flex items-center space-x-2.5 px-6 py-4  focus:z-20 focus:outline-none focus:ring-2 focus:ring-heading/80",
                          {
                            "bg-layer-3": isActive,
                            "hover:bg-layer-3": !isActive,
                          }
                        )
                      }
                    >
                      <div className="flex-shrink-0">
                        <div className="relative inline-block">
                          <Avatar picture={conversation.picture} title={conversation.title} />
                          <FontAwesomeIcon
                            className="absolute -bottom-1 -left-3 block h-5 w-5 bg-white rounded-md p-[2px]"
                            icon={CHANNEL_ICON[conversation.channel]}
                          />
                        </div>
                      </div>
                      <div className="flex-1">
                        <div className="flex items-center justify-between">
                          <h3 className="text-base font-semibold text-heading">
                            {conversation.title}
                          </h3>
                          <div className="flex items-center space-x-2">
                            <div className="text-sm font-medium text-text">
                              <RelativeTime
                                timestamp={conversation.timestamp}
                              />
                            </div>
                            <div hidden>
                              <button
                                type="button"
                                className="inline-flex cursor-pointer items-center justify-center rounded-xl border-none border-transparent bg-transparent p-2 font-semibold text-text hover:bg-heading/5 hover:text-heading focus:bg-heading/5 focus:outline-none focus:ring-2 focus:ring-heading/80 focus:ring-offset-0 disabled:opacity-30 disabled:hover:bg-transparent disabled:hover:text-text"
                              >
                                <EllipsisHorizontalIcon className="h-4 w-4" />
                              </button>
                            </div>
                          </div>
                        </div>
                        <p
                          className="text-sm font-medium text-text line-clamp-1"
                          style={{ lineBreak: "anywhere" }}
                        >
                          <ConversationSnippet conversation={conversation} />
                        </p>
                      </div>
                    </NavLink>
                  ))}
                  {!conversations.value.length && <div className="space-y-3 rounded-3xl bg-layer-2 px-4 pt-6 pb-10 text-center grid h-full place-items-center">
                    <div>
                      {/* <div className="inline-grid place-content-center rounded-3xl bg-layer-3 p-4">
                      <MoonIcon className="h-8 w-8 stroke-gradient gradient-lime" />
                    </div>
                    <span className="block text-sm font-semibold uppercase tracking-wide">
                      Step 1
                    </span> */}
                      <h3 className="text-xl font-semibold text-heading">
                        Empty Inbox
                      </h3>
                      <p className="font-medium">
                        You should see your messages here when you receive them.
                      </p>
                    </div>
                  </div>}
                </div>
              </div>
            </div>
          </div>
          <main className="flex-1">
            <Outlet context={{ account }} />
          </main>
        </div>
      </div>
    </>
  );
}

const ConversationsRoute: RouteObject = {
  id: "conversations",
  path: "conversations",
  element: <Conversations />,
  children: [ConversationRoute],
};

export default ConversationsRoute;
