import {
  faFacebookMessenger
} from "@fortawesome/free-brands-svg-icons";
import { useEffect } from "react";
import {
  RouteObject,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { MetaIntegrationConnect } from "./meta-connect";
import { MetaIntegrationDisconnect } from "./meta-disconnect";

const title = "Facebook Messenger";

export function FacebookIntegrationConnect() {
  return <MetaIntegrationConnect
    object="facebook"
    icon={faFacebookMessenger}
    title={title}
    subtitle="When the Conversation Meets the Community."
    description="Offer more than just support with Facebook Messenger, one of the most powerful online tools for customer contact today."
  />
}

function FacebookIntegration() {
  const { account } = useOutletContext<any>();
  const navigate = useNavigate();
  useEffect(() => {
    navigate(account?.facebook ? "options" : "connect", { replace: true });
  }, []);
  return <></>;
}

const FacebookIntegrationRoute: RouteObject = {
  id: "facebook",
  path: "facebook",
  children: [
    {
      index: true,
      element: <FacebookIntegration />,
    },
    {
      path: "connect",
      element: <FacebookIntegrationConnect />,
    },
    {
      path: "options",
      element: <MetaIntegrationDisconnect object="facebook" icon={faFacebookMessenger} title={title} />,
    },
  ],
};

export default FacebookIntegrationRoute;
