import { getApp } from "firebase/app";
import { Functions, HttpsCallableOptions, HttpsCallable, getFunctions, httpsCallable } from "firebase/functions";
import { httpsCallableFromURL } from "firebase/functions";

export default function callable<RequestData = unknown, ResponseData = unknown>(name: string, options?: HttpsCallableOptions): HttpsCallable<RequestData, ResponseData> {
    const functionsInstance: Functions = getFunctions(getApp(), "europe-west1")
    if (process.env.NODE_ENV !== "production")
        return httpsCallable(functionsInstance, name, options)
    else
        return httpsCallableFromURL(functionsInstance, `https://${name}-yfsosyqacq-ew.a.run.app`, options)

}