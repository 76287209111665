// import { register as registerServiceWorker } from "./serviceWorkerRegistration";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { StrictMode } from "react";
import { auth } from "./firebase";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
auth.authStateReady().then(
  () =>
    root.render(
      <StrictMode>
        <App />
      </StrictMode>
    )
);

// registerServiceWorker({
//   onSuccess() {},
//   onUpdate() {
//     console.log("New content is available; please refresh.");
//   },
// });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
